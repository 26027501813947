import React from "react"

import { MarkerWrapper } from "../MapStyles"

interface Props {
  text: number
}

export const MapMarker = ({ text }: Props) => (
  <MarkerWrapper>
    <span>{text}</span>
  </MarkerWrapper>
)
