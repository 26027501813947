import React from "react"
import { graphql } from "gatsby"

import { Store as Template } from "@components/Store/Store"

export const query = graphql`
  query($id: String) {
    page: sanityStore(_id: { eq: $id }) {
      _id
      _rawAddress(resolveReferences: { maxDepth: 10 })
      _rawLocation(resolveReferences: { maxDepth: 10 })
      _rawProductRanges(resolveReferences: { maxDepth: 10 })
      _rawSlug(resolveReferences: { maxDepth: 10 })
      country
      city
      email
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      productRanges {
        title
      }
      flagship
    }
  }
`

export default ({ data, ...props }) => <Template {...props} {...data} />
