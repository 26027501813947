import React from "react"

import { withStore } from "./withStore"
import { Map } from "./Map/Map"
import { StoreDetails } from "./Details/StoreDetails"
import { MapContent } from "./Map/MapStyles"

interface Props {
  content: any
}

export const Store = withStore(({ content }: Props) => (
  <MapContent>
    {content?.location?.lat && content.location.lng && <Map lat={content.location.lat} lng={content.location.lng} />}
    <StoreDetails content={content} />
  </MapContent>
))
