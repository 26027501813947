import React from "react"
import GoogleMapReact from "google-map-react"

import { withMap } from "./withMap"
import { MapMarker } from "./Marker/MapMarker"
import { MapWrapper } from "./MapStyles"

interface Props {
  apiKey: string
  lat: number
  lng: number
  options: any
}

export const Map = withMap(({ apiKey, lat, lng, options }: Props) => (
  <MapWrapper>
    <GoogleMapReact bootstrapURLKeys={{ key: apiKey }} defaultCenter={{ lat: lat, lng: lng }} defaultZoom={11} options={options}>
      <MapMarker lat={lat} lng={lng} text={1} />
    </GoogleMapReact>
  </MapWrapper>
))
