import React from "react"

import { withStoreDetails } from "./withStoreDetails"
import { Accordion } from "../../Accordion/Accordion"
import { Breadcrumb } from "../../Breadcrumb/Breadcrumb"
import { RichText } from "../../RichText/RichText"
import {
  BreadcrumbWrapper,
  PanelBg,
  PanelContentBg,
  PanelMarker,
  PanelContent,
  PanelDetails,
  PanelRow,
  PanelLabel,
  PanelLink,
  PanelP,
} from "../StoreStyles"

interface Props {
  active: string
  content: any
  locales: any
  paths: any
  setActive: any
  types: Array<string>
}

export const StoreDetails = withStoreDetails(({ active, content, locales, paths, setActive, types }: Props) => (
  <PanelBg>
    <PanelContent>
      <PanelContentBg>
        <BreadcrumbWrapper>
          <Breadcrumb paths={paths} />
        </BreadcrumbWrapper>

        <PanelRow>
          <PanelMarker>{locales.marker}</PanelMarker>
          <PanelDetails>
            <PanelLabel>{content.title}</PanelLabel>
            <PanelP as={`div`}>
              <RichText content={content.address} />
            </PanelP>
          </PanelDetails>
        </PanelRow>

        <Accordion active={active === types[0]} condensed id={types[0]} setActive={setActive} title={locales.range}>
          <PanelP>{content.productRanges.map(range => range).join(`, `)}</PanelP>
        </Accordion>

        {content.email && (
          <Accordion active={active === types[1]} condensed id={types[1]} setActive={setActive} title={locales.details}>
            <PanelLink as={`a`} href={`mailto:${content.email}`}>
              {content.email}
            </PanelLink>
          </Accordion>
        )}
      </PanelContentBg>
    </PanelContent>
  </PanelBg>
))
