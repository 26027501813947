import tw from "twin.macro"

import { Label, P } from "../Styled/Text"

export const StyledP = tw(P)`w-full tracking-normal text-grey-darker`
export const PanelBg = tw.div`w-full md:w-38 md:bg-grey-DEFAULT md:inset-y-0 md:right-0 border-b border-grey-DEFAULT md:border-b-0`
export const PanelContentBg = tw.div`md:bg-light p-2-4`
export const PanelMarker = tw.div`w-2-4 h-2-4 rounded-full bg-dark text-light font-sans font-light text-xs flex items-center justify-center`
export const PanelContent = tw.aside`block w-full md:px-2-4 mt-0 md:mt-9 md:top-9`
export const PanelDetails = tw.div`flex-1 ml-1-6`
export const PanelLabel = tw(Label)`mb-0-4`
export const PanelLink = tw(StyledP)`hover:underline`
export const PanelP = tw(P)`text-grey-mid text-sm tracking-relaxed`
export const PanelRow = tw.div`flex items-start justify-start`
export const BreadcrumbWrapper = tw.div`mb-2`
