import React from "react"

export const withStore = Component => ({ name = `Store`, page }) => {
  const content = {
    ...page,
    location: { ...page._rawLocation, type: (page._rawLocation || {})._type },
    slug: page._rawSlug.current,
    address: page._rawAddress,
    addressText: page._rawAddress?.[0]?.children?.[0]?.text,
    productRanges: page.productRanges.map(range => range.title),
  }

  Component.displayName = name
  return <Component content={content} />
}
